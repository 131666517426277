import * as React from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinimize,
  faExpand,
  faArrowsLeftRightToLine,
  IconDefinition,
  faUpDownLeftRight,
} from "@fortawesome/free-solid-svg-icons";
import { gettext } from "i18n";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import Flex from "theme/components/common/Flex";
import { getColor } from "theme/helpers/utils";
import PageHeader from "theme/components/common/PageHeader";
import { isEmpty } from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  GetMeasurementStation,
  GetMeasurementStation_station,
  GetMeasurementStation_station_datasources,
} from "../utils/__generated__/GetMeasurementStation";
import { GetDatasource } from "../utils/__generated__/GetDatasource";
import {
  GetDatasourcesByUUID,
  GetDatasourcesByUUIDVariables,
  GetDatasourcesByUUID_source,
} from "../utils/__generated__/GetDatasourcesByUUID";
import {
  GetDatasourceData,
  GetDatasourceDataVariables,
  GetDatasourceData_source_data,
} from "../utils/__generated__/GetDatasourceData";
import { GetDatasourceDataByUUID_source } from "../utils/__generated__/GetDatasourceDataByUUID";
import { alertFragment } from "./__generated__/alertFragment";
import MeasurementStationMap from "../components/map/MeasurementStationMap";
import { useCachedSubscription, usePolledQuery } from "../hooks/apollo";
import DateTimeRangeSelector, {
  usePersistentDateTimeRange,
} from "components/dates/DateTimeRangeSelector";
import PermissionContextCheck from "../components/auth/PermissionContextCheck";
import ExportLinks from "../components/export/ExportLinks";
import {
  useAuth,
  useIsAdmin,
  useIsQualityController,
  isStationAdmin,
} from "state/auth";
import { useModalContext, modalSetters } from "state/modal";
import apiClient from "../api";
import getMedian from "utils/filters";
import {
  DataPoint,
  DataSource,
  DataChartProps,
  getChartSeriesColor,
  FullDataSourceLoader,
  DataSourceAnnotation,
  defaultAnnotationColor,
  AdditionalDatapointData,
  manualDatapointSymbolSVG,
} from "utils/datacharts";
import { getLiteralArray } from "utils/hasura";

import "./MeasurementStationPage.scss";
import StationFileLinks from "../components/files/StationFileLinks";
import AlertSettingForm, {
  ALERT_LEVEL_COLORS,
  ALERT_LEVEL_AREA_COLORS,
} from "../components/modals/AlertSettingForm";
import ChartAnnotationForm from "../components/modals/ChartAnnotationForm";
import ChartAxisSettingsForm from "../components/modals/ChartAxisSettingsForm";
import DataRemovalSettingForm from "../components/modals/DataRemovalSettingForm";
import AdditionalChartsSelectorForm from "../components/modals/AdditionalChartsSelectorForm";
import DefaultTooltip from "../components/common/DefaultTooltip";
import { usePersistentState } from "../hooks/state";
import {
  timestampToDatetimeString,
  dateToString,
  formatNumberSafe,
  formatNumberLocale,
  parseNumberInput,
  stringToDate,
} from "utils/formatting";
import {
  GET_MEASUREMENT_STATION_SUBSCRIPTION,
  GET_DATASOURCE_DATA,
  GET_DATASOURCES_BY_UUID,
  getSourceUnitSymbol,
  useAlertStatus,
} from "../utils/stations";

const getDatapointValues = (
  datapoints: DataPoint[] | null,
  dataModifier: number = 1
) =>
  datapoints?.filter((d) => !d.invalid)?.map((d) => d.value * dataModifier) ||
  [];

const getAnnotationValues = (annotations: DataSourceAnnotation[]) =>
  annotations.filter((a) => a.value !== null).map((a) => a.value);

const getDataModifierForSource = (
  source: GetMeasurementStation_station_datasources
) => {
  let dataModifier = 1;
  if (source.type === "antiderivative_formula" && source.unit?.symbol === "l") {
    dataModifier = 0.001;
  }
  return dataModifier;
};

const getChartAlertMarkData = ({
  data,
  alerts = [],
}: {
  data: DataPoint[] | null;
  alerts?: alertFragment[];
}) => {
  const alertLines = alerts
    .map((alert) => {
      const alertLevel: string = alert.level;

      const lineStyle = {
        type: "solid",
        color: alert.active
          ? ALERT_LEVEL_COLORS[alertLevel]
          : "rgba(182, 193, 210, 0.5)",
      };

      if (!alert.lower_limit && !alert.upper_limit) {
        return [];
      } else if (alert.upper_limit && !alert.lower_limit) {
        return [{ name: "upper limit", yAxis: alert.upper_limit, lineStyle }];
      } else if (alert.lower_limit && !alert.upper_limit) {
        return [{ name: "lower limit", yAxis: alert.lower_limit, lineStyle }];
      }
      return [
        { name: "upper limit", yAxis: alert.upper_limit, lineStyle },
        { name: "lower limit", yAxis: alert.lower_limit, lineStyle },
      ];
    })
    .flat();

  const alertMarkAreas = alerts
    .map((alert) => {
      const alertLevel: string = alert.level;
      const itemStyle = {
        color: alert.active
          ? ALERT_LEVEL_AREA_COLORS[alertLevel]
          : "rgba(182, 193, 210, 0.1)",
      };

      if (!alert.lower_limit && !alert.upper_limit) {
        return [];
      } else if (alert.upper_limit && !alert.lower_limit) {
        return [[{ yAxis: alert.upper_limit }, { originY: "end", itemStyle }]];
      } else if (alert.lower_limit && !alert.upper_limit) {
        return [
          [{ originY: "start", itemStyle }, { yAxis: alert.lower_limit }],
        ];
      }
      return [
        [{ originY: "start" }, { yAxis: alert.lower_limit }],
        [{ yAxis: alert.upper_limit, itemStyle }, { originY: "end" }],
      ];
    })
    .flat();

  return {
    markLine: {
      data: !isEmpty(data) && alertLines,
      label: {
        position: "insideEndBottom",
        show: false,
      },
      symbol: "none",
      silent: true,
    },
    markArea: {
      silent: true,
      data: !isEmpty(data) && alertMarkAreas,
    },
  };
};

const getChartAnnotationSeries = ({
  annotations,
  source,
}: {
  annotations: DataSourceAnnotation[];
  source: GetMeasurementStation_station_datasources;
}) => {
  if (!annotations) {
    return null;
  }
  const unitSymbol = source.unit?.symbol ?? "";
  const annotationLines = annotations.map(
    (annotation: DataSourceAnnotation, idx: number) => ({
      type: "annotation",
      name: annotation.text,
      label: {
        formatter: (value: any) => {
          const annotationValue = value?.data?.value;
          const numericValue = parseNumberInput(value?.data?.name);
          const dateValue =
            window.isNaN(annotationValue) &&
            dayjs(annotationValue).format("DD.MM.YYYY HH:mm");

          const valueLabel =
            annotationValue !== undefined
              ? ` [${dateValue || `${annotationValue}${unitSymbol}`}]`
              : "";

          if (Number.isNaN(numericValue)) {
            return `${value?.data?.name}${valueLabel}`;
          }
          return `${formatNumberLocale(numericValue)}${valueLabel}`;
        },
        position: "middle",
        padding: [0, 0, -5, 0],
        show: true, // Change to "false" to hide annotation labels by default and show on hover
      },
      lineStyle: {
        type: "solid",
        color: annotation.color ? annotation.color : defaultAnnotationColor,
      },
      ...(annotation.value !== null && {
        yAxis: annotation.value,
      }),
      ...(annotation.timestamp !== null && {
        xAxis: annotation.timestamp,
      }),
    })
  );

  return {
    type: "line",
    name: "annotation",
    yAxisIndex: 0,
    zlevel: 1,
    markLine: {
      data: annotationLines,
      // label: {
      //   formatter: "{b}",
      //   position: "middle",
      //   show: true,
      // },
      symbol: "none",
      silent: false,
      triggerEvent: true,
    },
  };
};

const getDatapointsGroupedByInterval = (
  datapoints: DataPoint[],
  groupDateFormat: string
) => {
  const groupedDatapoints: DataPoint[] = [];
  const groupedDatapointsMap: Record<string, DataPoint[]> = {};

  datapoints.forEach((datapoint) => {
    const timestamp = dayjs(datapoint.timestamp);
    const timestampKey = timestamp.format(groupDateFormat);
    if (groupedDatapointsMap[timestampKey] === undefined) {
      groupedDatapointsMap[timestampKey] = [];
    }
    groupedDatapointsMap[timestampKey].push(datapoint);
  });

  Object.keys(groupedDatapointsMap).forEach((key) => {
    const datapoints = groupedDatapointsMap[key];
    const timestamp = dayjs(key).format("YYYY-MM-DD");
    const values = datapoints.map((d) => d.value);
    const meanValue = values.reduce((a, b) => a + b) / values.length;
    groupedDatapoints.push({
      timestamp: `${timestamp}T00:00:00.000Z`,
      value: meanValue,
      invalid: false,
    });
  });

  return groupedDatapoints;
};

const getChartSeriesForDatapoints = ({
  source,
  datapoints,
  subtractDatapoints = [],
  seriesData = {},
  medianVisible = false,
  alerts = [],
  seriesIndex = 0,
  yAxisIndex = 0,
  lineOnly = false,
  confidenceBand = false,
  annotations = [],
  datapointGroupDateFormat = null,
}: {
  source: GetMeasurementStation_station_datasources;
  datapoints: DataPoint[];
  subtractDatapoints?: DataPoint[];
  seriesData?: Object;
  areaStyle?: any;
  medianVisible?: boolean;
  alerts?: alertFragment[];
  seriesIndex?: number;
  yAxisIndex?: number;
  lineOnly?: boolean;
  confidenceBand?: boolean;
  annotations?: DataSourceAnnotation[];
  datapointGroupDateFormat?: string | null;
}) => {
  const series: any = [];
  const annotationSeries = getChartAnnotationSeries({
    annotations,
    source,
  });
  if (annotationSeries?.markLine?.data?.length) {
    series.push(annotationSeries);
  }

  if (!datapoints.length) {
    return series;
  }
  const dataModifier = getDataModifierForSource(source);
  const validSeriesColour = getChartSeriesColor(seriesIndex);
  const isManualPointSet = source.type === "manual_point_set";

  let lineColour = medianVisible
    ? `${getColor("info")}20` // Show at 20% opacity
    : `${getColor("info")}00`; // 100% transparent
  if (lineOnly) {
    // If no custom colouring is needed, render a simply line with a default colour
    lineColour = validSeriesColour;
  }
  if (isManualPointSet) {
    // Manual datapoint line is always hidden
    lineColour = `${getColor("info")}00`;
  }

  let itemStyleColor = medianVisible ? getColor("info") : validSeriesColour;
  if (confidenceBand) {
    itemStyleColor = `${getColor("info")}00`;
  }

  // Dot border colour; matches the line colour
  let borderColor = medianVisible ? getColor("info") : validSeriesColour;
  const seriesName = lineOnly ? `${getSourceUnitSymbol(source)}` : "hidden";

  // Adjust datapoints by data modifier and subtract relevant datapoints
  const adjustedDatapoints = datapoints.map((d, idx) => {
    const subtractDatapoint = subtractDatapoints[idx];
    const subtractValue = (subtractDatapoint?.value ?? 0) * dataModifier;

    const adjustedDatapoint: DataPoint = {
      ...d,
      value: d.value * dataModifier - subtractValue,
    };
    return adjustedDatapoint;
  });
  const groupedDatapoints = datapointGroupDateFormat
    ? getDatapointsGroupedByInterval(
        adjustedDatapoints,
        datapointGroupDateFormat
      )
    : adjustedDatapoints;

  const flattenedDatapoints = groupedDatapoints.map((d) => {
    return [d.timestamp, d.value];
  });

  series.push({
    type: "line",
    yAxisIndex,
    zlevel: 2,
    data: flattenedDatapoints,
    itemStyle: {
      color: itemStyleColor,
      borderColor,
      borderWidth: 2,
    },
    lineStyle: {
      color: lineColour,
      ...(!!confidenceBand && {
        opacity: 0,
      }),
    },
    ...getChartAlertMarkData({
      data: groupedDatapoints,
      alerts,
    }),
    showSymbol: isManualPointSet,
    ...((!confidenceBand && {
      symbol: isManualPointSet ? manualDatapointSymbolSVG : "circle",
      symbolSize: isManualPointSet ? 5 : 10,
    }) || {
      symbol: "none",
    }),
    smooth: false,
    name: seriesName,
    emphasis: {
      scale: true,
    },
    ...seriesData,
  });

  if (!lineOnly) {
    const getDatapointSegment = (d: DataPoint, index: number) => {
      const nextIdx = index + 1;
      const nextItem = groupedDatapoints[nextIdx];

      const segment: any = [
        new Date(d?.timestamp || nextItem?.timestamp).getTime(),
        (d?.value ?? nextItem?.value) * dataModifier,
        new Date(nextItem?.timestamp || d?.timestamp).getTime(), // Has to be numeric or api.value will return NaN in renderItem
        (nextItem?.value ?? d?.value) * dataModifier,
      ];
      return {
        value: segment,
        itemStyle:
          d?.invalid || nextItem?.invalid
            ? {
                color: medianVisible ? `#cccccc10` : `${getColor("primary")}20`,
              }
            : {
                color: medianVisible
                  ? `${getColor("info")}90`
                  : validSeriesColour,
              },
      };
    };

    series.push({
      type: "custom",
      name: `${getSourceUnitSymbol(source) || "[N/A]"}`,
      yAxisIndex,
      zlevel: 2,
      clip: true,
      renderItem: function (params: any, api: any) {
        const start = api.coord([
          dateToString(api.value(0), true),
          api.value(1),
        ]);
        const end = api.coord([dateToString(api.value(2), true), api.value(3)]);
        return {
          type: "line",
          shape: {
            x1: start[0],
            y1: start[1],
            x2: end[0],
            y2: end[1],
          },
          style: {
            lineWidth: isManualPointSet ? 0 : 2,
            stroke: api.visual("color"),
          },
        };
      },
      dimensions: ["x1", "y1", "x2", "y2"],
      encode: {
        x: [0, 1],
        y: [2, 3],
      },
      data: [...groupedDatapoints.map(getDatapointSegment)],
    });
  }
  return series;
};

const getYAxisForSeries = ({
  source,
  datapoints,
  yAxisIndex,
  scalingMin,
  scalingMax,
  manualScaling = false,
  annotations = [],
}: {
  source: GetMeasurementStation_station_datasources;
  datapoints: DataPoint[] | null;
  yAxisIndex: number;
  annotations?: DataSourceAnnotation[];
  scalingMin?: number;
  scalingMax?: number;
  manualScaling?: boolean;
}) => {
  const dataModifier = getDataModifierForSource(source);
  // For primary axis, consider annotation values in yAxis min/max
  const annotationValues = !yAxisIndex ? getAnnotationValues(annotations) : [];
  let yAxisMin;
  let yAxisMax;
  if (manualScaling && scalingMin !== undefined) {
    yAxisMin = scalingMin;
  } else {
    yAxisMin =
      datapoints?.length || annotationValues.length
        ? Math.min(
            ...[
              ...getDatapointValues(datapoints, dataModifier),
              ...annotationValues,
            ]
          )
        : 0;
  }
  if (manualScaling && scalingMax !== undefined) {
    yAxisMax = scalingMax;
  } else {
    yAxisMax =
      datapoints?.length || annotationValues.length
        ? Math.max(
            ...[
              ...getDatapointValues(datapoints, dataModifier),
              ...annotationValues,
            ]
          )
        : 0;
  }

  return {
    type: "value",
    position: yAxisIndex > 0 ? "right" : "left",
    offset: yAxisIndex > 0 ? (yAxisIndex - 1) * 55 : 0,
    min: yAxisMin,
    max: yAxisMax,
    scale: true, // TODO: could make this configurable
    splitLine: {
      lineStyle: {
        type: "dashed",
        color: getColor("200"),
      },
    },
    boundaryGap: false,
    axisLabel: {
      show: true,
      color: getColor("400"),
      margin: yAxisIndex > 0 ? 5 : 15,
      formatter: function (value: number) {
        return formatNumberLocale(value);
      },
      hideOverlap: true,
    },
    nameLocation: yAxisIndex > 0 ? "end" : "start",
    nameGap: yAxisIndex > 0 ? 5 : 10,
    name: yAxisIndex > 0 ? getSourceUnitSymbol(source) : "", // Hide primary axis name
    axisTick: { show: false },
    axisLine: {
      show: !yAxisIndex ? false : true,
      lineStyle: {
        color: getColor("400"),
      },
    },
    axisPointer: {
      label: {
        formatter: ({ value }: { value: number }) => {
          return formatNumberSafe(value);
        },
      },
    },
  };
};

export const DataChart = ({
  source,
  datapoints,
  alerts,
  startDate,
  endDate,
  additionalDatapoints,
  scalingMin,
  scalingMax,
  manualScaling = false,
  medianDatapoints = null,
  confidenceBand = false,
  datapointGroupDateFormat = null,
  labelDateFormat = null,
  isAdmin = false,
}: DataChartProps) => {
  const [chartInstance, setChartInstance] = React.useState<any>(null);
  const [lastSeriesLength, setLastSeriesLength] = React.useState<number>(0);

  /**
   * Use non-reactive / static version of setters instead of useModalContext
   * to prevent charts rerendering when any context value changes (e.g. a modal opens or closes).
   * We only need to trigger opening of the modal, but do not care about its current visibility state.
   */
  const { setActiveModal, setSelectedDataSource, setModalPayload } =
    modalSetters;

  const showAnnotationForm = React.useMemo(
    () => (source: DataSource, datapoint: DataPoint | null) => {
      setSelectedDataSource?.(source);
      setModalPayload?.({
        datapoint,
        isAdmin,
      });
      setActiveModal?.("annotation");
    },
    [setActiveModal, setSelectedDataSource, setModalPayload, isAdmin]
  );

  const dataModifier = getDataModifierForSource(source);
  const annotations: DataSourceAnnotation[] = source.datasource_annotation;

  // useMemo instead of useState + useEffect to avoid unnecessary rerenders
  const chartOptions = React.useMemo<any>(() => {
    const uniqueUnits: string[] = [
      // Ensure that the primary unit is at index 0 (even if the unit is not defined)
      getSourceUnitSymbol(source) || "[N/A]",
    ];
    const datapointsPerUnit: Record<string, DataPoint[]> = {
      [uniqueUnits[0]]: datapoints || [],
    };

    // First pass - group datapoints per unit
    if (additionalDatapoints) {
      additionalDatapoints.forEach(
        (additionalDatapoint: AdditionalDatapointData, idx: number) => {
          const additionalUnit = getSourceUnitSymbol(
            additionalDatapoint.source
          );
          if (!additionalUnit) {
            return;
          }
          const validDatapoints = additionalDatapoint.data.filter(
            (d) => !d.invalid
          );
          if (datapointsPerUnit[additionalUnit] === undefined) {
            datapointsPerUnit[additionalUnit] = [...validDatapoints];
          } else {
            datapointsPerUnit[additionalUnit] = [
              ...datapointsPerUnit[additionalUnit],
              ...validDatapoints,
            ];
          }
        }
      );
    }

    const yAxisList = [
      getYAxisForSeries({
        source,
        datapoints: datapointsPerUnit[uniqueUnits[0]],
        yAxisIndex: 0,
        annotations,
        scalingMin,
        scalingMax,
        manualScaling,
      }),
    ];

    if (additionalDatapoints) {
      // Second pass - create y-axis for each unique unit (new y-axis for every undefined unit)
      additionalDatapoints.forEach(
        (additionalDatapoint: AdditionalDatapointData, idx: number) => {
          const sourceKey = `${additionalDatapoint.source.source_id}_${additionalDatapoint.source.type}`;
          const unitSymbol = getSourceUnitSymbol(additionalDatapoint.source);

          if (
            (additionalDatapoint.seriesData as any)?.stack === "confidence-band"
          ) {
            return;
          }

          const additionalUnit = unitSymbol || sourceKey;
          if (!uniqueUnits.includes(additionalUnit)) {
            uniqueUnits.push(additionalUnit);
          }
          const yAxisIndex = uniqueUnits.indexOf(additionalUnit);

          // Do not create duplicate axes for the same unit
          if (!yAxisIndex || yAxisList[yAxisIndex] !== undefined) {
            return;
          }

          let validDatapoints;
          if (unitSymbol) {
            validDatapoints = datapointsPerUnit[unitSymbol];
          } else {
            validDatapoints = additionalDatapoint.data.filter(
              (d) => !d.invalid
            );
          }
          const yAxis = getYAxisForSeries({
            source: additionalDatapoint.source,
            datapoints: validDatapoints,
            yAxisIndex,
            scalingMin,
            scalingMax,
            manualScaling,
          });
          yAxisList.push(yAxis);
        }
      );
    }

    let gridTopOffset = (yAxisList.length - 1) * 18 + 10;
    if (gridTopOffset > 70) {
      gridTopOffset = 70;
    }
    // Third pass - assign datapoints to correct y-axis and create series
    const additionalSeries = (additionalDatapoints ?? [])
      .map((additionalDatapoint: AdditionalDatapointData, idx: number) => {
        const sourceKey = `${additionalDatapoint.source.source_id}_${additionalDatapoint.source.type}`;
        const additionalUnit =
          getSourceUnitSymbol(additionalDatapoint.source) || sourceKey;
        const seriesIndex = idx + 1;
        const validDatapoints = additionalDatapoint.data.filter(
          (d) => !d.invalid
        );
        const validSubtractDatapoints =
          additionalDatapoint.subtractData?.filter((d) => !d.invalid) ?? [];
        let yAxisIndex = uniqueUnits.indexOf(additionalUnit);
        if (yAxisIndex === -1) {
          yAxisIndex = 0;
        }

        return getChartSeriesForDatapoints({
          source: additionalDatapoint.source,
          datapoints: validDatapoints,
          subtractDatapoints: validSubtractDatapoints,
          seriesIndex,
          yAxisIndex,
          confidenceBand,
          datapointGroupDateFormat,
          lineOnly: true,
          seriesData: additionalDatapoint.seriesData,
        });
      })
      .flat();

    const medianSeries = [
      {
        type: "line",
        zlevel: 10,
        name: `${getSourceUnitSymbol(source) || "[N/A]"}`,
        data: medianDatapoints?.length
          ? medianDatapoints.map((d) => [d.timestamp, d.value * dataModifier])
          : [],
        itemStyle: {
          color: getColor("primary"),
          borderColor: getColor("primary"),
          borderWidth: 2,
        },
        lineStyle: {
          color: getColor("primary"),
        },
        ...getChartAlertMarkData({
          data: datapoints,
          alerts: alerts ?? [],
        }),
        showSymbol: false,
        symbol: "circle",
        symbolSize: 10,
        smooth: false,
        emphasis: {
          scale: true,
        },
      },
    ];

    const series = [
      ...getChartSeriesForDatapoints({
        source,
        datapoints: datapoints || [],
        medianVisible: !!medianDatapoints?.length,
        datapointGroupDateFormat,
        alerts: alerts ?? [],
        yAxisIndex: 0,
        annotations,
      }),
      // Additional datapoints from other station sources
      ...additionalSeries,
      // Median datapoints
      ...((medianDatapoints?.length && medianSeries) || []),
    ];

    return {
      tooltip: {
        trigger: "axis",
        padding: [7, 10],
        backgroundColor: getColor("100"),
        borderColor: getColor("300"),
        textStyle: getColor("dark"),
        borderWidth: 1,
        transitionDuration: 0,
        axisPointer: {
          type: "none",
        },
        formatter: (params: any) => {
          // TODO: apparently we need to use `formatter` instead of the easier options to hide the "hidden" axis
          // but TBH this whole thing seems a bit complicated...
          const visibleSeries = params.filter(
            (s: any) => s.seriesName !== "hidden"
          );

          if (!visibleSeries.length) {
            return "";
          }
          let primaryValue = visibleSeries[0].value[1];
          const seriesText = visibleSeries
            .map((s: any) => {
              let formattedValue;
              let numericValue = parseNumberInput(s.value[1]);
              let seriesName = s.seriesName;
              if (!Number.isNaN(numericValue) && seriesName === "Max") {
                numericValue +=
                  visibleSeries.find((vs: any) => vs.seriesName === "Min")
                    ?.value[1] ?? 0;
              }

              if (Number.isNaN(numericValue)) {
                formattedValue = formatNumberSafe(s.value[1]);
              } else {
                formattedValue = formatNumberLocale(numericValue);
              }
              if (!["Min", "Max"].includes(seriesName)) {
                seriesName = confidenceBand ? "Mean" : seriesName;
                primaryValue = formatNumberLocale(s.value[1]);
              }
              return `${s.marker}&nbsp;<b>${formattedValue} ${
                seriesName || ""
              }</b>`;
            })
            .join("<br/>");

          // Add series unit to timestamp for confidence band charts
          const seriesUnit = confidenceBand
            ? `&nbsp;${getSourceUnitSymbol(source)}`
            : "";
          const dateObject = stringToDate(params[0].axisValue);
          const axisLabelValue = labelDateFormat
            ? dateObject.format(labelDateFormat)
            : visibleSeries[0]?.axisValueLabel;

          const displayPrimaryValue = confidenceBand
            ? `&nbsp;<b>${primaryValue}</b>`
            : "";
          return `${axisLabelValue}${seriesUnit}:${displayPrimaryValue}<br/>${seriesText}`;
        },
        valueFormatter: (value: number | string) => {
          if (typeof value === "number") {
            return formatNumberLocale(value);
          }
          return value.toString();
        },
      },
      xAxis: {
        type: "time",
        boundaryGap: false,
        axisLine: {
          lineStyle: {
            color: getColor("300"),
          },
        },
        min: startDate,
        max: endDate,
        axisTick: { show: false },
        axisLabel: {
          color: getColor("400"),
          margin: 15,
          // rotate: 30,
          // Echarts takes care of handling tick sizes etc
          formatter: {
            minute: "{HH}:{mm}",
            hour: "{HH}:{mm}",
            day: "{d}.{M}.",
            month: "{d}.{M}.",
            year: "{d}.{M}.{yyyy}",
          },
          hideOverlap: true,
        },
        axisPointer: {
          label: {
            formatter: ({ value }: { value: number }) =>
              timestampToDatetimeString(value),
          },
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: yAxisList,
      series,
      grid: {
        right: "3%",
        left: 20,
        bottom: "10%",
        top: gridTopOffset,
        containLabel: true,
      },
    };
  }, [
    additionalDatapoints,
    alerts,
    dataModifier,
    datapoints,
    medianDatapoints,
    endDate,
    startDate,
    source,
    annotations,
    confidenceBand,
    datapointGroupDateFormat,
    labelDateFormat,
    manualScaling,
    scalingMax,
    scalingMin,
  ]);

  React.useEffect(() => {
    if (!chartInstance) {
      return;
    }
    if (!chartOptions || !chartOptions.series.length) {
      return;
    }

    // Fix for lines not disappearing when series length changes
    const echartInstance = chartInstance.getEchartsInstance();
    if (lastSeriesLength !== chartOptions.series.length) {
      echartInstance.clear();
      setLastSeriesLength(chartOptions.series.length);
    }
    echartInstance.setOption(chartOptions);
    echartInstance.off("click");
    echartInstance.off("mouseover");
    echartInstance.off("mouseout");

    echartInstance.on("click", (params: any) => {
      if (
        params.componentType === "series" &&
        params.componentSubType === "line"
      ) {
        const data: DataPoint = {
          timestamp: params.data[0],
          value: params.data[1],
          invalid: false,
        };
        showAnnotationForm(source, data);
      }
    });
  }, [
    chartOptions,
    chartInstance,
    source,
    lastSeriesLength,
    showAnnotationForm,
  ]);

  if (!chartOptions) {
    return <></>;
  }

  return (
    <ReactEChartsCore
      ref={setChartInstance}
      option={chartOptions}
      echarts={echarts}
      style={{ height: "18.75rem" }}
      opts={{
        locale: "FI",
      }}
    />
  );
};

const MeasurementStationPage = () => {
  const { identifier } = useParams();
  const { user } = useAuth();
  const [dateRange, setDateRange] = usePersistentDateTimeRange();
  const [startDate, endDate] = dateRange;

  const [showHidden, setShowHidden] = usePersistentState(
    "ShowHiddenSources",
    false
  );

  const { data, error, loading } = useCachedSubscription<GetMeasurementStation>(
    GET_MEASUREMENT_STATION_SUBSCRIPTION,
    {
      variables: {
        identifier,
        visible: showHidden ? [true, false] : [true],
      },
    }
  );

  if (!data) {
    return <div />;
  }
  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  }
  if (data?.station.length === 0) {
    return (
      <div>Measurement station with identifier {identifier} not found</div>
    );
  }

  const station = data!.station[0];
  const isAdmin = isStationAdmin(user, station);

  return (
    <div className="MeasurementStationPage">
      <PageHeader title={station.name} className="mb-3" />
      <Row className={"g-3 mb-3"}>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col xs={12} sm={6}>
                  <div>
                    <strong>Show measurements between</strong>
                  </div>
                  <DateTimeRangeSelector
                  disableMidnightRounding
                    range={dateRange}
                    setRange={setDateRange}
                    quickChoices={[
                      ["1 h", 1 * 60 * 60 * 1000],
                      ["3 h", 3 * 60 * 60 * 1000],
                      ["24 h", 24 * 60 * 60 * 1000],
                      ["7 d", 7 * 24 * 60 * 60 * 1000],
                      ["14 d", 14 * 24 * 60 * 60 * 1000],
                      ["30 d", 30 * 24 * 60 * 60 * 1000],
                    ]}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <Flex justifyContent="md-end">
                    <StationFileLinks stationIdentifier={station.identifier} />
                    <ExportLinks
                      startDate={startDate}
                      endDate={endDate}
                      station={station}
                    />
                  </Flex>
                  <Flex justifyContent="md-end">
                    <AdminLinks stationIdentifier={station.identifier} />
                  </Flex>
                </Col>
              </Row>
              {isAdmin && (
                <Row>
                  <Col xs={12} sm={6}>
                    <Form.Check
                      checked={showHidden}
                      type="switch"
                      id="custom-switch-ignore-spikes"
                      label={gettext("Show hidden measurements")}
                      onChange={() => setShowHidden(!showHidden)}
                    />
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className={"g-3 mb-3"}>
        {!!(station.lat && station.lon) && (
          <Col md={6} xxl={4} xs={12}>
            <Card>
              <Card.Body>
                <MeasurementStationMap
                  stations={[station]}
                  height={359}
                  mapLayer={station.map_layer ?? undefined}
                />
              </Card.Body>
            </Card>
          </Col>
        )}
        <DataSourceCards
          station={station}
          loading={loading}
          startDate={startDate}
          endDate={endDate}
        />
      </Row>
    </div>
  );
};

// TODO: this is another somewhat ugly hack...
let queuedDataSourceCardsUpdateTimeoutId: any = null;

const getIconForSize = (size: number): IconDefinition => {
  const idx = size % 3;
  return [faMinimize, faArrowsLeftRightToLine, faExpand][idx];
};

const chartSizeMap: Record<number, Record<string, number>> = {
  0: { md: 6, xxl: 4 },
  1: { md: 6, xxl: 8 },
  2: { md: 12, xxl: 12 },
};

const getNextChartSize = (size: number): number => (size + 1) % 3;

export interface DataSourceWidgetsProps {
  activeSources: DataSource[];
  station: GetMeasurementStation_station;
  dataSources: GetMeasurementStation_station_datasources[];
  startDate?: Date;
  endDate?: Date;

  temporaryDataSources: GetMeasurementStation_station_datasources[] | null;
  setTemporaryDataSources: React.Dispatch<
    React.SetStateAction<GetMeasurementStation_station_datasources[] | null>
  >;
  medianFilterValues: number[];
  setMedianFilterValues: (values: number[]) => void;
  medianFilterEnabled: boolean[];
  setMedianFilterEnabled: (values: boolean[]) => void;
}
const DataSourceWidgets = ({
  activeSources,
  station,
  dataSources,
  startDate,
  endDate,
  temporaryDataSources,
  setTemporaryDataSources,
  medianFilterValues,
  setMedianFilterValues,
  medianFilterEnabled,
  setMedianFilterEnabled,
}: DataSourceWidgetsProps) => {
  const sourceUUIDs = activeSources.map((source) => source.source_uuid);
  const literalUUIDs = getLiteralArray(sourceUUIDs);

  const { data: fullDataSources, loading } = useCachedSubscription<
    GetDatasourcesByUUID,
    GetDatasourcesByUUIDVariables
  >(GET_DATASOURCES_BY_UUID, {
    variables: {
      uuids: literalUUIDs,
    },
  });

  return (
    <>
      {fullDataSources?.source.map((source, index) => {
        const sourceKey = `${source.type}-${source.source_id}`;

        return (
          <DataSourceCell
            key={`data_source_cell_${sourceKey}`}
            index={index}
            source={source}
            loading={loading}
            station={station}
            dataSources={dataSources}
            startDate={startDate}
            endDate={endDate}
            temporaryDataSources={temporaryDataSources}
            setTemporaryDataSources={setTemporaryDataSources}
            medianFilterValue={
              (medianFilterEnabled[index] && medianFilterValues[index]) || 0
            }
            medianFilterValues={medianFilterValues}
            setMedianFilterValues={setMedianFilterValues}
            medianFilterEnabled={medianFilterEnabled}
            setMedianFilterEnabled={setMedianFilterEnabled}
          />
        );
      })}
    </>
  );
};

export interface DataSourceCellProps {
  index: number;
  source: GetDatasourcesByUUID_source;
  loading: boolean;
  station: GetMeasurementStation_station;
  dataSources: GetMeasurementStation_station_datasources[];
  startDate?: Date;
  endDate?: Date;
  medianFilterValue: number;

  temporaryDataSources: GetMeasurementStation_station_datasources[] | null;
  setTemporaryDataSources: React.Dispatch<
    React.SetStateAction<GetMeasurementStation_station_datasources[] | null>
  >;
  medianFilterValues: number[];
  setMedianFilterValues: (values: number[]) => void;
  medianFilterEnabled: boolean[];
  setMedianFilterEnabled: (values: boolean[]) => void;
}
const DataSourceCell = ({
  index,
  source,
  loading,
  station,
  dataSources,
  startDate,
  endDate,
  medianFilterValue,
  temporaryDataSources,
  setTemporaryDataSources,
  medianFilterValues,
  setMedianFilterValues,
  medianFilterEnabled,
  setMedianFilterEnabled,
}: DataSourceCellProps) => {
  const additionalDataSources = React.useMemo(
    () =>
      station.station_widgets
        ?.filter((widget) => widget.base_source_uuid === source.source_uuid)
        .map((widget) =>
          widget.station_widget_sources?.map((source) => source.source_uuid)
        )
        .flat(),
    [station, source.source_uuid]
  );

  const chartSize = source.widget_size ?? 0;

  const nextChartSize = getNextChartSize(chartSize);
  const alerts: alertFragment[] = React.useMemo(() => {
    return (source?.alerts ?? []) as alertFragment[];
  }, [source]);

  const sizeMd = chartSizeMap[chartSize].md;
  const sizeXxl = chartSizeMap[chartSize].xxl;

  const DataSourceCardMemo = React.useMemo(() => {
    return (
      <DataSourceCard
        index={index}
        source={source}
        station={station}
        alerts={alerts}
        dataSources={dataSources}
        additionalDataSources={additionalDataSources}
        startDate={startDate}
        endDate={endDate}
        medianFilterValue={medianFilterValue}
        nextChartSize={nextChartSize}
        temporaryDataSources={temporaryDataSources}
        setTemporaryDataSources={setTemporaryDataSources}
        medianFilterValues={medianFilterValues}
        setMedianFilterValues={setMedianFilterValues}
        medianFilterEnabled={medianFilterEnabled}
        setMedianFilterEnabled={setMedianFilterEnabled}
      />
    );
  }, [
    index,
    source,
    station,
    alerts,
    dataSources,
    additionalDataSources,
    startDate,
    endDate,
    medianFilterValue,
    nextChartSize,
    temporaryDataSources,
    setTemporaryDataSources,
    medianFilterValues,
    setMedianFilterValues,
    medianFilterEnabled,
    setMedianFilterEnabled,
  ]);

  return (
    <Col md={sizeMd} xxl={sizeXxl} xs={12}>
      {loading && (
        <Spinner
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
          animation="border"
          size="sm"
        />
      )}
      {!loading && DataSourceCardMemo}
    </Col>
  );
};

interface SourceActionsProps {
  index: number;
  source: DataSource;
  station: GetMeasurementStation_station;
  dataSources: GetMeasurementStation_station_datasources[];
  alerts: alertFragment[];
  nextChartSize: number;
  startDate?: Date;
  endDate?: Date;
  temporaryDataSources: GetMeasurementStation_station_datasources[] | null;
  setTemporaryDataSources: React.Dispatch<
    React.SetStateAction<GetMeasurementStation_station_datasources[] | null>
  >;
  medianFilterValues: number[];
  setMedianFilterValues: (values: number[]) => void;
  medianFilterEnabled: boolean[];
  setMedianFilterEnabled: (values: boolean[]) => void;
  dragHandleProps: any;
}
const SourceActions = ({
  index,
  source,
  alerts,
  nextChartSize,
  startDate,
  endDate,
  station,
  dataSources,
  medianFilterValues,
  setMedianFilterValues,
  medianFilterEnabled,
  setMedianFilterEnabled,
  temporaryDataSources,
  setTemporaryDataSources,
  dragHandleProps,
}: SourceActionsProps) => {
  const sourceKey = `${source.type}-${source.source_id}`;
  const isAdmin = useIsAdmin(station);
  const isQualityController = useIsQualityController(station);
  const { setActiveModal, setSelectedDataSource, setModalPayload } =
    useModalContext();

  const showAlertSetting = React.useMemo(
    () => (source: DataSource) => (e: any) => {
      setSelectedDataSource(source);
      setActiveModal("alert");
    },
    [setSelectedDataSource, setActiveModal]
  );
  const showAnnotationForm = React.useMemo(
    () => (source: DataSource, datapoint: DataPoint | null) => {
      setSelectedDataSource(source);
      setModalPayload?.({
        datapoint,
        isAdmin,
      });
      setActiveModal("annotation");
    },
    [setActiveModal, setSelectedDataSource, setModalPayload, isAdmin]
  );
  const showAxisSettingsForm = React.useMemo(
    () => (source: DataSource) => {
      setSelectedDataSource(source);
      setActiveModal("axisSettings");
    },
    [setActiveModal, setSelectedDataSource]
  );
  const showDataRemovalSetting = (source: DataSource) => (e: any) => {
    setSelectedDataSource(source);
    setActiveModal("dataRemoval");
  };
  const showAdditionalCharts = (source: DataSource) => (e: any) => {
    setSelectedDataSource(source);
    setActiveModal("additionalCharts");
  };
  const [configSelectorIndex, setConfigSelectorIndex] = React.useState<
    number | null
  >(null);
  const toggleConfigSelector = (index: number) => (e: any) => {
    if (configSelectorIndex === null || configSelectorIndex !== index) {
      setConfigSelectorIndex(index);
    } else {
      setConfigSelectorIndex(null);
    }
  };
  const { activeAlertCount, isAlertTriggered } = useAlertStatus({
    alerts,
    startDate,
    endDate,
  });

  let alertStyle = {};
  if (activeAlertCount > 0) {
    alertStyle = {
      opacity: 1,
      color: "green",
    };
  }
  if (isAlertTriggered) {
    alertStyle = {
      ...alertStyle,
      color: "red",
    };
  }

  const queueUpdate = (updatedSources: DataSource[]) => {
    if (queuedDataSourceCardsUpdateTimeoutId) {
      clearTimeout(queuedDataSourceCardsUpdateTimeoutId);
    }
    queuedDataSourceCardsUpdateTimeoutId = setTimeout(() => {
      queuedDataSourceCardsUpdateTimeoutId = null;
      apiClient
        .request("/organization-admin/set-order-and-visibility", {
          method: "POST",
          data: {
            stationId: station.id,
            sources: updatedSources.map((source, order) => ({
              id: source.source_id,
              order, // Always use the latest order
              type: source.type,
              visible: source.visible,
              widget_size: source.widget_size,
            })),
          },
        })
        .then(() => {
          toast.success("Order/visibility updated successfully");
          // Wait 1 second to allow for the subscription to update
          setTimeout(() => {
            if (!queuedDataSourceCardsUpdateTimeoutId) {
              // Clear temporary data sources only if haven't queued another update
              setTemporaryDataSources(null);
            }
          }, 1000);
        })
        .catch((e) => {
          // TODO: clear temporary datasources or not?
          console.error(e);
          toast.success("Error updating order/visibility: " + e.toString());
        });
    }, 2500);
  };

  const toggleVisibilityHandler = (index: number) => (e: any) => {
    e.preventDefault();
    if (index < 0 || index >= dataSources.length) {
      return;
    }
    const tmp = temporaryDataSources
      ? [...temporaryDataSources]
      : [...dataSources];
    const item = { ...tmp[index] };
    item.visible = !item.visible;
    tmp[index] = item;
    setTemporaryDataSources(tmp);
    queueUpdate(tmp);
  };

  const toggleChartSizeHandler = (index: number) => (e: any) => {
    e.preventDefault();
    if (index < 0 || index >= dataSources.length) {
      return;
    }
    const tmp = temporaryDataSources
      ? [...temporaryDataSources]
      : [...dataSources];
    const item = { ...tmp[index] };
    item.widget_size = getNextChartSize(item.widget_size ?? 0);
    tmp[index] = item;
    setTemporaryDataSources(tmp);
    queueUpdate(tmp);
  };

  return (
    <React.Fragment>
      <div
        className={classNames("admin-actions", {
          visible: isAlertTriggered,
        })}
      >
        {source.type === "antiderivative_formula" &&
          !!startDate &&
          !!endDate && (
            <>
              <DefaultTooltip
                iconClassName="me-2"
                popBody={<p>Cumulative flow between the selected dates.</p>}
              />
            </>
          )}
        <FontAwesomeIcon
          icon={"gear"}
          className="me-2"
          onClick={toggleConfigSelector(index)}
        />
        {configSelectorIndex === index && (
          <div className="configSelector">
            <div>
              <span>Median filter</span>
              <Form.Check
                type="checkbox"
                checked={medianFilterEnabled[index] ?? false}
                onChange={(e: any) => {
                  const newValues = [...medianFilterEnabled];
                  newValues[index] = e.target.checked;
                  setMedianFilterEnabled(newValues);
                }}
              />
            </div>
            {medianFilterEnabled[index] && (
              <div>
                <span>Points</span>
                <Form.Control
                  type="number"
                  min={0}
                  value={medianFilterValues[index] ?? 0}
                  onChange={(e: any) => {
                    const newValues = [...medianFilterValues];
                    let numericValue = parseInt(e.target.value, 10);
                    // Cannot have even numbers in median filter's window size
                    if (numericValue % 2 === 0) {
                      numericValue +=
                        medianFilterValues[index] > numericValue ? -1 : 1;
                    }
                    if (numericValue < 0) {
                      numericValue = 0;
                    }
                    newValues[index] = numericValue;
                    setMedianFilterValues(newValues);
                  }}
                />
              </div>
            )}
            <div className="config-button">
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => showAnnotationForm(source, null)}
              >
                Manage annotations
              </Button>
            </div>
            {isAdmin && (
              <div className="config-button">
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={() => showAxisSettingsForm(source)}
                >
                  Axis settings
                </Button>
              </div>
            )}
          </div>
        )}
        {/** TODO: Needs finishing / refactoring.
         * Currently OrganizationAdminAlertAPIViews does not
         * support filteirng by roles, so will reject requested by quality controllers.
         */}
        {isQualityController &&
          isAdmin && // TODO: remove this when the above is fixed
          (source.type === "formula" || source.type === "channel") && (
            // antiderivatives don't have alerts atm
            <>
              <FontAwesomeIcon
                icon="bell"
                key={`bell-${sourceKey}`}
                className="me-2"
                shake={isAlertTriggered}
                style={alertStyle}
                onClick={showAlertSetting(source)}
              />
              <FontAwesomeIcon
                icon="filter"
                key={`filter-${sourceKey}`}
                className="me-2"
                onClick={showDataRemovalSetting(source)}
              />
              <FontAwesomeIcon
                icon="chart-line"
                key={`other-stations-${sourceKey}`}
                className="me-2"
                onClick={showAdditionalCharts(source)}
              />
              <div {...dragHandleProps}>
                <FontAwesomeIcon icon={faUpDownLeftRight} className="me-2" />
              </div>
            </>
          )}
        {isAdmin && (
          <>
            <FontAwesomeIcon
              icon={source.visible ? "eye" : "eye-slash"}
              className="me-2"
              onClick={toggleVisibilityHandler(index)}
            />
            <FontAwesomeIcon
              icon={getIconForSize(nextChartSize)}
              className="me-2"
              onClick={toggleChartSizeHandler(index)}
            />
          </>
        )}
      </div>
    </React.Fragment>
  );
};

interface DataSourceCardsProps {
  station: GetMeasurementStation_station;
  loading?: boolean;
  startDate?: Date;
  endDate?: Date;
}

const DataSourceCards = ({
  station,
  startDate,
  endDate,
}: DataSourceCardsProps) => {
  const dataSources = station.datasources;

  const { activeModal, modalPayload, selectedDataSource } = useModalContext();
  const [temporaryDataSources, setTemporaryDataSources] = React.useState<
    DataSource[] | null
  >(null);
  const [stationMedianFilterValues, setStationMedianFilterValues] =
    usePersistentState(
      "stationMedianFilterValues",
      {},
      undefined,
      window.localStorage
    );
  const [stationMedianFilterEnabled, setStationMedianFilterEnabled] =
    usePersistentState(
      "stationMedianFilterEnabled",
      {},
      undefined,
      window.localStorage
    );

  const medianFilterValues = React.useMemo(() => {
    return stationMedianFilterValues[station.id] ?? [];
  }, [stationMedianFilterValues, station.id]);
  const setMedianFilterValues = (values: number[]) => {
    setStationMedianFilterValues({
      ...stationMedianFilterValues,
      [station.id]: values,
    });
  };
  const medianFilterEnabled = React.useMemo(() => {
    return stationMedianFilterEnabled[station.id] ?? [];
  }, [stationMedianFilterEnabled, station.id]);
  const setMedianFilterEnabled = (values: boolean[]) => {
    setStationMedianFilterEnabled({
      ...stationMedianFilterEnabled,
      [station.id]: values,
    });
  };
  const stationDatasourcesUuids =
    dataSources?.map((dataSource) => dataSource.source_uuid) || [];

  const updateOrder = (stationId: any, updatedSources: any) => {
    return apiClient.request("/organization-admin/set-order-and-visibility", {
      method: "POST",
      data: {
        stationId,
        sources: updatedSources.map((source: any, order: any) => ({
          id: source.source_id,
          order, // Always use the latest order
          type: source.type,
          visible: source.visible,
          widget_size: source.widget_size,
        })),
      },
    });
  };

  const handleDragDrop = (results: any) => {
    const { source, destination, type } = results;
    if (!destination) return;
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;

    if (type === "CARD") {
      const tmp = temporaryDataSources
        ? [...temporaryDataSources]
        : [...dataSources];
      const draggableIndex = tmp.findIndex(
        (item) => item.source_id == source.droppableId
      );
      const draggableItem = tmp[draggableIndex];
      tmp.splice(draggableIndex, 1);
      tmp.splice(destination.index, 0, draggableItem);
      setTemporaryDataSources(tmp);
      updateOrder(station.id, tmp).then(() => {
        toast.success("Successfully updated order");
      });
    }
  };

  const activeSources = temporaryDataSources ?? dataSources;
  return (
    <React.Fragment>
      {!!selectedDataSource && (
        <>
          {activeModal === "dataRemoval" && (
            <DataRemovalSettingForm stationId={station.id} />
          )}
          {activeModal === "alert" && <AlertSettingForm />}
          {activeModal === "annotation" && (
            <ChartAnnotationForm
              stationSourceUUIDs={stationDatasourcesUuids}
              sourceUUID={selectedDataSource.source_uuid}
            />
          )}
          {activeModal === "axisSettings" && (
            <ChartAxisSettingsForm
              source={selectedDataSource as GetDatasourcesByUUID_source}
              station={station}
            />
          )}
          {activeModal === "additionalCharts" && (
            <AdditionalChartsSelectorForm station={station} />
          )}
        </>
      )}
      <DragDropContext onDragEnd={handleDragDrop}>
        <DataSourceWidgets
          activeSources={activeSources}
          station={station}
          dataSources={dataSources}
          startDate={startDate}
          endDate={endDate}
          temporaryDataSources={temporaryDataSources}
          setTemporaryDataSources={setTemporaryDataSources}
          medianFilterValues={medianFilterValues}
          setMedianFilterValues={setMedianFilterValues}
          medianFilterEnabled={medianFilterEnabled}
          setMedianFilterEnabled={setMedianFilterEnabled}
        />
      </DragDropContext>
    </React.Fragment>
  );
};

const getMedianDatapoints = ({
  medianFilterValue,
  datapoints,
}: {
  medianFilterValue: number;
  datapoints: GetDatasourceData_source_data[];
}) => {
  if (medianFilterValue <= 0) {
    return [];
  }

  const expandedDatapoints: GetDatasourceData_source_data[] = [];
  const numericValues: number[] = datapoints.map(
    (datapoint: GetDatasourceData_source_data) => parseFloat(datapoint.value)
  );

  const medianValues: number[] = getMedian(numericValues, medianFilterValue);
  medianValues.forEach((value: number, index: number) => {
    expandedDatapoints[index] = {
      __typename: "data",
      timestamp: datapoints[index].timestamp,
      value,
      invalid: datapoints[index].invalid,
    };
  });

  return expandedDatapoints;
};

// TODO: extract these from this file
export interface DataSourceCardProps {
  index: number;
  source: DataSource;
  station: GetMeasurementStation_station;
  dataSources: GetMeasurementStation_station_datasources[];
  startDate?: Date;
  endDate?: Date;
  fullDataSource?: GetDatasource | undefined;
  medianFilterValue: number;
  nextChartSize: number;
  alerts: alertFragment[];
  additionalDataSources: string[];

  temporaryDataSources: GetMeasurementStation_station_datasources[] | null;
  setTemporaryDataSources: React.Dispatch<
    React.SetStateAction<GetMeasurementStation_station_datasources[] | null>
  >;
  medianFilterValues: number[];
  setMedianFilterValues: (values: number[]) => void;
  medianFilterEnabled: boolean[];
  setMedianFilterEnabled: (values: boolean[]) => void;
}
export const DataSourceCard = ({
  index,
  source,
  startDate,
  endDate,
  alerts,
  station,
  dataSources,
  medianFilterValue,
  additionalDataSources,
  nextChartSize,
  temporaryDataSources,
  setTemporaryDataSources,
  medianFilterValues,
  setMedianFilterValues,
  medianFilterEnabled,
  setMedianFilterEnabled,
}: DataSourceCardProps) => {
  const { data: dataSourceData } = usePolledQuery<
    GetDatasourceData,
    GetDatasourceDataVariables
  >(GET_DATASOURCE_DATA, {
    variables: {
      type: source.type!,
      sourceId: source.source_id!,
      startDate,
      endDate,
    },
    pollInterval: 60 * 1000,
  });
  const isAdmin = useIsAdmin(station);

  const [additionalDatapoints, setAdditionalDatapoints] = React.useState<
    AdditionalDatapointData[]
  >([]);

  const datapoints = React.useMemo(
    () => dataSourceData?.source?.data ?? [],
    [dataSourceData]
  );

  const { isAlertTriggered } = useAlertStatus({
    alerts,
    startDate,
    endDate,
  });

  const medianDatapoints = React.useMemo(
    () =>
      getMedianDatapoints({
        medianFilterValue,
        datapoints,
      }),
    [medianFilterValue, datapoints]
  );

  React.useEffect(() => {
    if (!additionalDatapoints.length) {
      return;
    }
    if (!additionalDataSources?.length && additionalDatapoints.length) {
      setAdditionalDatapoints([]);
      return;
    }
    // Identify data sources that are no longer selected
    const additionalDatapointsToRemove = additionalDatapoints.filter(
      (additionalDatapoint: AdditionalDatapointData) =>
        !additionalDataSources?.find(
          (additionalSourceUUID: string) =>
            additionalSourceUUID === additionalDatapoint.source.source_uuid
        )
    );
    if (!additionalDatapointsToRemove.length) {
      return;
    }
    // Remove cached datapoints for data sources that are no longer selected
    setAdditionalDatapoints([
      ...additionalDatapoints.filter(
        (additionalDatapoint: AdditionalDatapointData) =>
          !additionalDatapointsToRemove.find(
            (additionalDatapointToRemove: AdditionalDatapointData) =>
              additionalDatapointToRemove.source.source_id ===
                additionalDatapoint.source.source_id &&
              additionalDatapointToRemove.source.type ===
                additionalDatapoint.source.type
          )
      ),
    ]);
  }, [additionalDataSources, additionalDatapoints]);

  const dataChartMemo = React.useMemo(() => {
    const stationWidget = station.station_widgets?.find(
      (widget) => widget.base_source_uuid === source.source_uuid
    );
    return (
      <DataChart
        source={source}
        isAdmin={isAdmin}
        datapoints={datapoints}
        medianDatapoints={medianDatapoints}
        additionalDatapoints={additionalDatapoints}
        startDate={startDate}
        alerts={alerts}
        endDate={endDate}
        manualScaling={stationWidget?.axis_scale_manual ?? false}
        scalingMin={stationWidget?.axis_scale_min}
        scalingMax={stationWidget?.axis_scale_max}
      />
    );
  }, [
    source,
    isAdmin,
    datapoints,
    medianDatapoints,
    additionalDatapoints,
    startDate,
    endDate,
    alerts,
    station,
  ]);

  const unitSymbol = getSourceUnitSymbol(source);

  return (
    <Droppable droppableId={`${source.source_id}`} type="CARD">
      {(provided) => (
        <div
          className="droppable"
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          <Draggable
            key={source.source_id}
            draggableId={source.source_id?.toString() || ""}
            index={index}
          >
            {(provided) => (
              <Card
                className={classNames("DataSourceCard", {
                  "not-visible": !source.visible,
                  "alert-triggered": isAlertTriggered,
                })}
                {...provided.draggableProps}
                ref={provided.innerRef}
              >
                <Card.Header>
                  <h5>
                    <div>
                      {source.name} {unitSymbol}
                    </div>
                    {!!additionalDataSources?.length && (
                      <span className="additional-data-sources">
                        {additionalDatapoints?.map(
                          (
                            additionalDatapointData: AdditionalDatapointData,
                            idx: number
                          ) => {
                            const addSource = additionalDatapointData.source;
                            const addStation =
                              additionalDatapointData.source.station;
                            const sourceName = `${
                              addSource.name
                            } ${getSourceUnitSymbol(addSource)} (${
                              addStation?.name
                            })`;
                            return (
                              <span
                                key={`additional_data_source_${addSource.source_uuid}`}
                                className="additional-data-source"
                                style={{
                                  color: getChartSeriesColor(idx + 1),
                                }}
                              >
                                {sourceName}
                              </span>
                            );
                          }
                        )}
                      </span>
                    )}
                  </h5>
                  <SourceActions
                    index={index}
                    source={source}
                    alerts={alerts}
                    nextChartSize={nextChartSize}
                    startDate={startDate}
                    endDate={endDate}
                    medianFilterEnabled={medianFilterEnabled}
                    setMedianFilterEnabled={setMedianFilterEnabled}
                    station={station}
                    dataSources={dataSources}
                    medianFilterValues={medianFilterValues}
                    setMedianFilterValues={setMedianFilterValues}
                    temporaryDataSources={temporaryDataSources}
                    setTemporaryDataSources={setTemporaryDataSources}
                    dragHandleProps={provided.dragHandleProps}
                  />
                </Card.Header>
                <Card.Body>
                  {additionalDataSources?.map((additionalSourceUUID) => (
                    <FullDataSourceLoader
                      key={`additional_loader_${additionalSourceUUID}`}
                      sourceUUID={additionalSourceUUID}
                      startDate={startDate}
                      endDate={endDate}
                      additionalDatapoints={additionalDatapoints}
                      setSourceData={(
                        source: GetDatasourceDataByUUID_source,
                        additionalData: GetDatasourceData_source_data[]
                      ) => {
                        setAdditionalDatapoints([
                          ...additionalDatapoints.filter(
                            (additionalDatapoint: AdditionalDatapointData) =>
                              additionalDatapoint.source.source_id !==
                                source.source_id ||
                              additionalDatapoint.source.type !== source.type
                          ),
                          {
                            source,
                            data: additionalData,
                          },
                        ]);
                      }}
                    />
                  ))}
                  {dataChartMemo}
                </Card.Body>
              </Card>
            )}
          </Draggable>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const UserLinks = ({ stationIdentifier }: { stationIdentifier: string }) => {
  return (
    <div className="stationAdminActions">
      <PermissionContextCheck
        category="source_edit"
        requiredPermission="add_manual_point_set"
        target={stationIdentifier}
      >
        <Button
          as={Link as any}
          to={`/stations/${stationIdentifier}/manual-datapoints`}
          variant="outline-primary"
          size="sm"
        >
          Manual/lab data
        </Button>
      </PermissionContextCheck>
      <Button
        as={Link as any}
        to={`/stations/${stationIdentifier}/organization-member-edit`}
        variant="outline-primary"
        size="sm"
      >
        Edit
      </Button>
    </div>
  );
};

const AdminLinks = ({ stationIdentifier }: { stationIdentifier: string }) => {
  const { user } = useAuth();
  if (!user?.admin) {
    return <UserLinks stationIdentifier={stationIdentifier} />;
  }
  return (
    <div className="stationAdminActions">
      <Button
        as={Link as any}
        to={`/stations/${stationIdentifier}/audit`}
        variant="outline-primary"
        size="sm"
      >
        Audit log
      </Button>
      <Button
        as={Link as any}
        to={`/stations/${stationIdentifier}/conversions`}
        variant="outline-primary"
        size="sm"
      >
        Conversion table
      </Button>
      <Button
        as={Link as any}
        to={`/stations/${stationIdentifier}/formulas`}
        variant="outline-primary"
        size="sm"
      >
        Formulas
      </Button>
      <Button
        as={Link as any}
        to={`/stations/${stationIdentifier}/manual-datapoints`}
        variant="outline-primary"
        size="sm"
      >
        Manual/lab data
      </Button>
      <Button
        as={Link as any}
        to={`/stations/${stationIdentifier}/edit`}
        variant="outline-primary"
        size="sm"
      >
        Edit
      </Button>
    </div>
  );
};

export default MeasurementStationPage;
