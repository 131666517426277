import React, { useState } from "react";
import dayjs from "dayjs";
import { useQuery } from "@apollo/client";
import { Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import AdvanceTable from "theme/components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "theme/components/common/advance-table/AdvanceTableWrapper";
import PageHeader from "../theme/components/common/PageHeader";
import MeasurementStationMap from "../components/map/MeasurementStationMap";
import {
  GET_MEASUREMENT_STATIONS,
  sortStationList,
  STATION_ORIGIN_FMI,
} from "../utils/stations";
import { GetMeasurementStations_station } from "utils/__generated__/GetMeasurementStations";
import { usePersistentState } from "../hooks/state";
import { searchIncludes } from "../utils/forms";
import { gettext } from "../i18n";
import "./MeasurementStationsPage.scss";

const getLink = (rowData: any, text: string | React.ReactNode) => (
  <Link to={`/stations/${rowData.row.original.identifier}`}>{text}</Link>
);

const columns = [
  {
    accessor: "organization.name",
    Header: () => <React.Fragment>Organization</React.Fragment>,
  },
  {
    accessor: "name",
    Header: () => <React.Fragment>Name</React.Fragment>,
    Cell: (rowData: any) => getLink(rowData, rowData.row.original.name),
  },
  {
    accessor: "status",
    Header: () => <React.Fragment>Status</React.Fragment>,
    Cell: (rowData: any) =>
      getLink(rowData, gettext(rowData.row.original.status)),
  },
  {
    id: "last_data_received",
    accessor: (station: GetMeasurementStations_station) => {
      const receivedAt = station.last_data_received?.[0]?.received_at;
      return receivedAt ? dayjs(receivedAt).unix() : 0;
    },
    Header: () => <React.Fragment>Last submission</React.Fragment>,
    Cell: (rowData: any) => {
      const receivedAt =
        rowData.row.original?.last_data_received?.[0]?.received_at;
      const receivedDateFormatted = receivedAt
        ? dayjs(receivedAt).format("L LT")
        : "N/A";
      return getLink(rowData, receivedDateFormatted);
    },
  },
  {
    id: "identifier",
    sortType: "string",
    accessor: (station: GetMeasurementStations_station) => station.identifier,
    Header: () => <React.Fragment>Identifier</React.Fragment>,
    Cell: (rowData: any) => getLink(rowData, rowData.row.original.identifier),
  },
];

const MeasurementStationsPage = () => {
  const { data, loading, error } = useQuery(GET_MEASUREMENT_STATIONS);
  const [rememberedPath, setRememberedPath] = usePersistentState(
    "rememberedPath",
    ""
  );
  const navigate = useNavigate();
  React.useEffect(() => {
    if (rememberedPath) {
      navigate(rememberedPath);
      setRememberedPath("");
    }
  }, [navigate, rememberedPath, setRememberedPath]);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  if (loading) {
    return <div />;
  }
  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  }

  return (
    <div className="MeasurementStationsPage">
      <Card className="mb-3 stations-map">
        <Card.Body>
          <MeasurementStationMap stations={data.station} />
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <div className="station-table-actions">
            <input
              id="station-search"
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              placeholder={gettext("Search ...")}
            />
          </div>
          <AdvanceTableWrapper
            columns={columns}
            data={sortStationList(
              data.station,
              (row) => row.origin !== STATION_ORIGIN_FMI
            ).filter(
              (row) =>
                searchIncludes(row.name, searchTerm) ||
                searchIncludes(row.organization?.name ?? "", searchTerm) ||
                searchIncludes(row.identifier, searchTerm) ||
                searchIncludes(row.status, searchTerm)
            )}
            sortable
            pagination
            perPage={30}
            selection={undefined}
            selectionColumnWidth={undefined}
            afterPrepareRow={(row) => {
              const rowProps = row.getRowProps();
              // Show alert if station is active and last data received is older than 24 hours
              const matchingLastData = row.original.last_data_received?.[0];
              const currentDate = dayjs();
              const lastDataReceivedDate = matchingLastData?.received_at
                ? dayjs(matchingLastData?.received_at)
                : currentDate;
              const dateDiff = currentDate.diff(lastDataReceivedDate, "day");
              const showAlert =
                row.original.status === "active" &&
                (dateDiff >= 1 || matchingLastData?.received_at === undefined);
              row.getRowProps = () => ({
                ...rowProps,
                className: showAlert ? "alert" : row.original.status,
              });
            }}
          >
            <AdvanceTable
              table
              tableProps={{
                bordered: true,
                striped: true,
                className: "stationListTable",
              }}
            />
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>
    </div>
  );
};
export default MeasurementStationsPage;
